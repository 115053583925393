<template>
    <van-popup class="vanPopup" v-model="show" :close-on-click-overlay="false">
        <div class="alert" :class="{'alertwidth':alertwidth,'alert_bg01':alert_bg01,'alert_bg02':alert_bg02}">
            <div class="clear palm" @click="clear">
                <!-- <img src="@/assets/image/home/close_pop.png" alt=""> -->
            </div>
            <div class="alertTitle" v-if="alertTitle">
                {{ alertTitle }}
            </div>
            <div class="content">
                <slot></slot>
            </div>
            <slot name="btns">
                <ul class="btns" v-if="minorButtonText || mainButtonText">
                    <li v-if="minorButtonText">
                        <button class="btn left palm" @click="handleMinor">{{ minorButtonText }}</button>
                    </li>
                    <li v-if="mainButtonText">
                        <button class="btn right palm" @click="handleMain">{{ mainButtonText }}</button>
                    </li>
                </ul>
            </slot>
            <slot name="bottom"></slot>
        </div>
    </van-popup>
</template>

<script>
import { Popup } from 'vant';

export default {
    name: 'BuyBox',

    components: {
        VanPopup: Popup
    },

    props: {
        alert_bg02:{
            type: Boolean,
            default: false
        },
        alert_bg01:{
            type: Boolean,
            default: false
        },
        alertTitle:{
            type:String,
            default:''
        },
        // 弹窗显示或隐藏
        show: {
            type: Boolean,
            default: false
        },
        // 主要的按钮
        mainButtonText: {
            type: String,
            default: ''
        },
        // 次要的按钮       
        minorButtonText: {
            type: String,
            default: ''
        },
        alertwidth:{
            type: Boolean,
            default: false
        },
        goReload:{
            type:Boolean,
            default:false
        },
    },

    model: {
        prop: 'show',
        event: 'change'
    },

    data(){
        return {
            isBuy: false
        }
    },

    methods: {
        clear(){
            this.$emit('change', false);
            if(this.goReload === true){
                location.reload();
            }
        },

        // 次要按钮
        handleMinor(){
            this.$emit('alert-minor', false)
        },

        // 主要按钮
        handleMain(){
            this.$emit('alert-main', false)
        },
    }
}
</script>

<style>
.van-popup{
    background: none !important;
}
</style>
<style lang="less" scoped>
.alertTitle{
   color: #F8C10A;
   text-align: center;
   font-size: 26px;
   line-height: 22px;
   margin: 30px 0px 20px;
   text-transform: uppercase;
   position: relative;
   z-index: 3;
}
.content{
    // overflow-y:auto;
}
@media screen and (min-width:769px) {
    .clear{
        // right: 0px !important;
        img{
            width: 20px;
            height: 20px;
        }
    }
}
.alertwidth{
    width: 800px !important;
}
.alert{
    width: 680px;
    // background: #000000;
    // background: url('../assets/image/holy/alert_bg.png') no-repeat center center/100% 100%;
    overflow-x: hidden !important;
    
}
.alert_bg01{
    width: 680px;
    height: 622px;
    border: 0;
    // background: url('../assets/image/mint/alert_bg.png') no-repeat center top/100% 100%;
}
.clear{
    width: 20px;
    position: absolute;
    top: 40px;
    right: 30px;
    z-index: 4;
}

@media screen and (max-width:768px) {
    .vanPopup{
        background-color:rgba(0, 0, 0, 0) !important;
    }
    .alert{
        border-radius: 8px;
        width: 315px !important;
    }
    .btns{
        button{
            width: 10.42rem;
            height: 2.7rem;
            font-size: 1.14rem;
            border-radius: 3px;
        }
    }
    .clear{
        img{
            width: 14px;
        }
        top: 24px;
        right: 24px;
    }
    .alert_bg01{
        width: 315px;
        height: 520px;
        // background: url('../assets/image/mint/alert_bg_h5.png') no-repeat center center/100%;
    }
    .alert_bg02{
        height: 380px;
        // background: url('../assets/image/mint/alert_bg_h502.png') no-repeat center top/100% 100%;
    }
    .alertTitle{
        font-size: 22px;
        line-height: 22px;
        margin: 15px 0px;
    }
    .alertwidth{
        width: 315px !important;
    }
}

</style>